<template>
  <div>
    <h2>科学上网</h2>
    <p>点击自动更新 ClashX 订阅信息并导入，省去每次失效后需要粘贴等麻烦步骤</p>
    <div>
      <a-button type="primary" icon="import" @click="promptPassword">
        <a :href="url1" class="vpn1 vpn"> 导入机场1</a>
      </a-button>
      <a-divider>分割线</a-divider>
      <a-button type="primary" icon="import" @click="handleClickNo">
        导入机场2 (待添加)
      </a-button>
      <a-divider>分割线</a-divider>
      <a-button type="primary" icon="import" @click="handleClickNo">
        导入机场3 (待添加)
      </a-button>
    </div>
    <!-- <a href="ClashX://install-config?url=******&name=小东西儿-一元机场.ink">小东西儿-一元机场.ink</a> -->
    <!-- <a href="ClashX://install-config?url=******&name=小东西儿-1元机场.com">小东西儿-1元机场.com</a> -->
    <!-- <a href="ClashX://install-config?url=******&name=小东西儿-1元机场.com">小东西儿-备用机场</a> -->
  </div>
</template>

<script>
export default {
  name: 'VpnPage',
  data() {
    return {
      description: '科学上网',
      noWTime: new Date().toISOString().slice(0, 10).replace(/-/g, ''),
      urlData1: 'https://dy.beiyong.top/api/v1/client/subscribe?token=91e004bbe6566fb533bedb910f6ba112',
      url1: ''
    }
  },
  created() {
    // this.promptPassword()

  },
  methods: {
    getUrl1() {
      this.url1 = `ClashX://install-config?url=${this.urlData1}&name=备用机场-` + this.noWTime;
    },
    promptPassword() {
      const password = prompt('请输入密码');
      if (password === '2019') {
        this.getUrl1();
      } else {
        alert('密码错误');
      }
    },
    handleClickNo() {
      this.$notification.warning({
        message: '暂未',
        description:
          '暂未添加, 请稍后再试',
      });
    }
  }
}
</script>
<style lang="less" scoped>
.vpn {
  color: #fff;
}
</style>